import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react'
import { Layout } from '../components/layout';
import { Heroheader } from '../components/heroheader';
import { MultiplyYourChances } from '../components/multiply-your-chances';
import { Offers } from '../components/offers';
import { MultiplyEvenMore } from '../components/multiply-even-more';
import { StayTuned } from '../components/stay-tuned';
import { Footer } from '../components/footer';

const IndexPage = ({location}) => {

  const [lang, setLang] = useState('cn');

  return (
    <Layout>
      <div className="language">
        <span className="lang">eng</span>
        <StaticImage src="../images/flag-eng.png" className="flag" alt="english flag icon"></StaticImage>
        <button className={"switcher "+lang} onClick={()=>{setLang( lang === "eng" ? "cn" : "eng" )}} aria-label="Change Language Here"></button>
        <StaticImage src="../images/flag-cn.png" className="flag" alt="chinese flag icon"></StaticImage>
        <span className="lang">中文</span>
      </div>
      { lang === "eng" && <div className="lang-eng">
          <Heroheader lang="eng"/>
          <MultiplyYourChances lang="eng"/>         
          <MultiplyEvenMore lang="eng" />
          <StayTuned lang="eng" />
          <Offers lang="eng" />
          <Footer lang="eng"/>
        </div>
      }
      { lang === "cn" && <div className="lang-eng">
          <Heroheader lang="cn"/>
          <MultiplyYourChances lang="cn"/>         
          <MultiplyEvenMore lang="cn" />
          <StayTuned lang="cn" />
          <Offers lang="cn" />
          <Footer lang="cn"/>
        </div>
      }
    </Layout>
  )
}

export default IndexPage
